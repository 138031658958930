document.addEventListener('DOMContentLoaded',function(){
    
    document.querySelector('#send-contact').addEventListener('submit',function(e){
        e.preventDefault();
        let data = new FormData()
        data.append('nome',document.querySelector('#formname').value);
        data.append('email',document.querySelector('#formemail').value);
        data.append('telefone',document.querySelector('#formphone').value);
        data.append('assunto',document.querySelector('#send-contact').getAttribute('data-page'));
        data.append('texto',document.querySelector('#formmessage').value);

        $("#formsend").val('ENVIANDO')
        fetch('../../mailer2.php',{
            method : 'post',
            body   : data
        })
        .then(function(resp){
            if(resp.status == 200){
                geral.conversion_page()
                // alert('obrigado')
                window.location.href = '/obrigado'
            }
        })
    });

})