let geral = {
    menuToggle: function(){
        $('.menu__hamburger').click(function(){
            // $('.menu__container').slideToggle()
        })

    },
    submenu:function(){
        $(document).on("click", '.navigation__link--hassubmenu', function(e){
            
            // $(this).find('+ .submenu__container').slideToggle()
        })
    },
    sliderTestimonials:function(){
        $('.testimonials__slick').slick({
            infinite: true,
            autoplay: true,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve"><g><polygon points="11.8,45.7 10.4,44.3 30.8,24 10.4,3.7 11.8,2.3 33.5,24  "></polygon></g></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve"><g><polygon points="11.8,45.7 10.4,44.3 30.8,24 10.4,3.7 11.8,2.3 33.5,24  "></polygon></g></svg></button>'

        })
    },
    sliderSteps:function(){
        if($(window).width() < 980){
            $('.carrossel').slick({
                infinite: false,
                autoplay: false,
                slidesToShow: 2,
                slidesToScroll: 1,
                prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve"><g><polygon points="11.8,45.7 10.4,44.3 30.8,24 10.4,3.7 11.8,2.3 33.5,24  "></polygon></g></svg></button>',
                nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve"><g><polygon points="11.8,45.7 10.4,44.3 30.8,24 10.4,3.7 11.8,2.3 33.5,24  "></polygon></g></svg></button>',
                responsive: [
                    {
                      breakpoint: 768,
                      settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      }
                    }
                  ]
    
            })
        }
    },
    sliderClinica:function(){
        $('.content-flex__carrossel').slick({
            infinite: true,
            autoplay: false,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 1,
            prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve"><g><polygon points="11.8,45.7 10.4,44.3 30.8,24 10.4,3.7 11.8,2.3 33.5,24  "></polygon></g></svg></button>',
            nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="Layer_1" style="enable-background:new 0 0 48 48;" version="1.1" viewBox="0 0 48 48" xml:space="preserve"><g><polygon points="11.8,45.7 10.4,44.3 30.8,24 10.4,3.7 11.8,2.3 33.5,24  "></polygon></g></svg></button>',
            responsive: [
                {
                  breakpoint: 768,
                  settings: {
                    slidesToShow: 1
                  }
                }
              ]

        })
    },
    inputHandler:function(masks, max, event) {
        var c = event.target;
        var v = c.value.replace(/\D/g, '');
        var m = c.value.length > max ? 1 : 0;
        VMasker(c).unMask();
        VMasker(c).maskPattern(masks[m]);
        c.value = VMasker.toPattern(v, masks[m]);
    },
    maskphone:function(){
        var telMask = ['(99) 9999-99999', '(99) 99999-9999'];
        var tel = document.querySelector('#formphone');
        VMasker(tel).maskPattern(telMask[0]);
        tel.addEventListener('input', geral.inputHandler.bind(undefined, telMask, 14), false);

        if($('body').hasClass('avaliacao-page')){
            var tel = document.querySelector('#formalopeciatelefone');
            VMasker(tel).maskPattern(telMask[0]);
            tel.addEventListener('input', geral.inputHandler.bind(undefined, telMask, 14), false);
        }
    },
    menuFixed:function(){
        if(window.innerWidth > 768){
            $(window).scroll(function(){
                if($('body').scrollTop() > $('.header').height()){
                    $('header').addClass('header--fixed')
                    $('body').css('paddingTop',130)
                }else{
                    $('header').removeClass('header--fixed')
                }
            })
        }
        
    },
    toggleAnswer:function(){
        $('.questions__ask').click(function(){
            // $('.questions__aswer').removeClass('.questions__aswer--active');
            $(this).siblings().removeClass('questions__aswer--active')
            $(this).toggleClass('questions__aswer--active')

            // $(this).next('.questions__aswer').slideToggle()
        })
    },
    scrollSuave:function(){
        $('a[href^="#"]').on('click', function(e) {
            e.preventDefault();
            var id = $(this).attr('href'),
                    targetOffset = $(id).offset().top;
                    
            $('html, body').animate({ 
                scrollTop: targetOffset
            }, 500);
        });
    },
    whatsLink:function(){
        var tel = '552432120288'   
        if($(window).width() > 768){
            $('.social__whatsapp').attr('href','https://web.whatsapp.com/send?phone='+tel) 
        }else{
            $('.social__whatsapp').attr('href','https://wa.me/'+tel)
        }

        $('.social__whatsapp').click(function(e){
            geral.gtag_report_conversion()
        })
        
    },
    formCalvicie:function(){
        $('.formtratamento__step-two').click(function(e){
            e.preventDefault()

            if($('.formtratamento__point input:checked').length == 0){
                Swal.fire({
                    icon:'error',
                    text:'É necessário escolher um gral de alopecia antes de continuar.'
                })
            }else{
                $('.formtratamento__one').hide()
                $('.formtratamento__two').show()
            }
        })
    },
    formavaliacao:function(){
        $('input[name="calvicie"]').click(function(){
            $(this).parent('.formtratamento__point').siblings().removeClass('formtratamento__point--active')
            $(this).parent('.formtratamento__point').addClass('formtratamento__point--active')
        })
    },
    gtag_report_conversion: function(){
        var callback = function () {
            if (typeof(url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': 'AW-589735565/x9XjCKyOhOoBEI3NmpkC',
            'event_callback': callback
        });
        
        return false;
    },
    conversion_page:function(){
        gtag('event', 'conversion', {'send_to': 'AW-589735565/PueGCJ3zl-oBEI3NmpkC'});
    },
    init: function(){
        this.whatsLink()
        // this.menuToggle()
        // this.submenu()
        // this.sliderTestimonials()
        // this.sliderSteps()
        this.maskphone()
        // this.sliderClinica()
        // this.formCalvicie()
        // this.formavaliacao()
        // this.toggleAnswer()
        // this.scrollSuave()

    }
}
geral.init()

